<template>
  <v-container>
    <BaseTitlePage />

    <BaseSearchPage
      title="Pesquise por um cliente para iniciar uma movimentação"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          lazy-validation
          v-model="valid"
          @submit.prevent="init"
        >
          <v-row dense>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <BaseCustomersAutocomplete
                id="filter"
                name="filter"
                label="Cliente"
                hide-details
                required
                :rules="[required]"
                v-model="filter"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseButton
                id="btn-init"
                name="btn-init"
                type="submit"
                color="primary"
                title="Iniciar"
                height="40"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';

export default {
  mixins: [rulesMixin],

  data: () => ({
    valid: true,
    filter: null
  }),

  methods: {
    init() {
      if (!this.$refs.form.validate(true)) return;

      this.$router.push({
        name: 'movimentacoes-list',
        params: { customerId: this.filter }
      });
    }
  }
};
</script>
